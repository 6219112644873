import { Navbar, Nav, Dropdown} from 'rsuite';
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';

const NavBar = ({title}) => {
  const { currentUser } = useContext(AuthContext);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
        navigate("/login");
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }

  return (
    <div className='navbar' style={{position: 'sticky', top: 0, zIndex: 1000}}>
      <Navbar>
        <Navbar.Brand style={{fontWeight: "800"}}>{title}</Navbar.Brand>
        <Nav pullRight>
          {currentUser ? (
            <Dropdown title={`${currentUser.position}, ${currentUser.name}`} trigger="hover">
              <Dropdown.Item onClick={handleSignOut}>Logout</Dropdown.Item>
            </Dropdown>
          ) : (
            <Nav.Item>Login</Nav.Item>
          )}
        </Nav>
      </Navbar>
    </div>
  )
}

export default NavBar