import 'rsuite/dist/rsuite.min.css';


import { Sidenav, Nav, Sidebar, Navbar } from 'rsuite';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import MagicIcon from '@rsuite/icons/legacy/Magic';
import OffRoundIcon from '@rsuite/icons/OffRound';
import NoticeIcon from '@rsuite/icons/Notice';
import DetailIcon from '@rsuite/icons/Detail';
import MemberIcon from '@rsuite/icons/Member';
import CalendarIcon from '@rsuite/icons/Calendar';
import CogIcon from '@rsuite/icons/legacy/Cog';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';

import { useState, useContext, forwardRef } from 'react';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: '#34c3ff',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
};

const NavToggle = ({ expand, onChange }) => {
  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Nav>
        <Nav.Menu
          noCaret
          placement="topStart"
          trigger="click"
          title={<CogIcon style={{ width: 20, height: 20 }} size="sm" />}
        >
          <Nav.Item href="/profile">My profile</Nav.Item>
          <Nav.Item href="/account-setting">Account Settings</Nav.Item>
        </Nav.Menu>
      </Nav>

      <Nav pullRight>
        <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
          {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const NavLink = forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
      {children}
    </Link>
  ));

const SideBar1 = () => {
    const [expand, setExpand] = useState(true);
    const {currentUser} = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        dispatch({ type: "LOGOUT" });
        localStorage.clear();
        navigate("/login");
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }
  
    return (
        <div className='sidebar' style={{textAlign: "left"}}>
            <Sidebar
            style={{ display: 'flex', flexDirection: 'column' }}
            width={expand ? 260 : 56}
            collapsible
        >
            {
                expand ? 
                <Sidenav.Header>
                    <div style={headerStyles}>
                    <span style={{ marginLeft: 12 }}>BRAND</span>
                    </div>
                </Sidenav.Header>
                : <div></div>
            }
            <Sidenav expanded={expand} defaultOpenKeys={['3', '4']}>
              <Sidenav.Body style={{ height: 'calc(100vh - 56px)', overflowY: 'auto' }}>
                <Nav activeKey="1">
                  {
                    currentUser.position === "Admin"
                    ? <Nav.Menu eventKey="1" title="Dashboard" icon={<DashboardIcon />}>
                    <Nav.Item eventKey="1-1">Overview</Nav.Item>
                    <Nav.Item eventKey="1-2">Notifications</Nav.Item>
                    <Nav.Item eventKey="1-3" as={NavLink} href="/users-registration">Register New Agents</Nav.Item>
                  </Nav.Menu>
                  :<div></div>
                  }
                  <Nav.Item eventKey="2" icon={<GroupIcon />} href="/project-list">
                    Project Lists
                  </Nav.Item>
                  <Nav.Item eventKey="3" icon={<GroupIcon />}>
                    Leaves & Claims
                  </Nav.Item>
                  <Nav.Menu eventKey="4" title="Sales" icon={<MagicIcon />}>
                    <Nav.Item eventKey="4-1">Sales Order</Nav.Item>
                    <Nav.Item eventKey="4-2">Commissions</Nav.Item>
                    <Nav.Item eventKey="4-4">My Overriding Claims</Nav.Item>
                    <Nav.Item eventKey="4-4">Overriding Commissions</Nav.Item>
                  </Nav.Menu>
                  <Nav.Menu eventKey="5" title="Subsales" icon={<MagicIcon />}>
                    <Nav.Item eventKey="5-1">Subsales Order</Nav.Item>
                    <Nav.Item eventKey="5-2">My Subsales Claims</Nav.Item>
                    <Nav.Item eventKey="5-3">DL's Subsales Commissions</Nav.Item>
                  </Nav.Menu>
                  <Nav.Menu eventKey="6" title="Rentals" icon={<MagicIcon />}>
                    <Nav.Item eventKey="6-1">Rentals Order</Nav.Item>
                    <Nav.Item eventKey="6-2">My Rentals Claims</Nav.Item>
                    <Nav.Item eventKey="6-3">DL's Rentals Commissions</Nav.Item>
                  </Nav.Menu>
                  <Nav.Menu eventKey="7" title="Referral" icon={<MemberIcon />}>
                    <Nav.Item eventKey="7-1">Recruitments</Nav.Item>
                    <Nav.Item eventKey="7-2">Roadshow Contacts</Nav.Item>
                  </Nav.Menu>
                  <Nav.Menu eventKey="8" title="Office Management" icon={<CalendarIcon />}>
                    <Nav.Item eventKey="8-1">Office Slots</Nav.Item>
                  </Nav.Menu>
                  <Nav.Item eventKey="9" icon={<DetailIcon />}>
                    Documents
                  </Nav.Item>
                  <Nav.Item eventKey="10" icon={<NoticeIcon />}>
                    Announcement
                  </Nav.Item>
                  <Nav.Item eventKey="5" icon={<OffRoundIcon />} onClick={handleSignOut}>
                    Logout
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
          </Sidebar>
        </div>
    )
}

export default SideBar1