export const projectData = [
    {
        id: 1,
        name: "Sejati Lakeside Phase 1",
        commission: "1.8",
        excludeSST: "No",
    },
    {
        id: 2,
        name: "Sejati Lakeside Phase 2",
        commission: "1.8",
        excludeSST: "No",
    },
]

export const excludeSSTData = ["Yes", "No"];