import { 
  Container,  
  Content, 
  Form, 
  ButtonToolbar, 
  Button, 
  FlexboxGrid, 
  Dropdown, 
  DatePicker, 
  useToaster, 
  Message,
} from 'rsuite';
import SideBar1 from '../../components/SideBar1/SideBar1';
import { positions, genderData } from './data';
import {  useState, useContext  } from "react";
import { doc, serverTimestamp, setDoc } from "firebase/firestore"; 
import { auth, db, } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { generateRandomPassword } from '../../Helpers/GenerateRandomPassword';
import NavBar from '../../components/NavBar/NavBar';
import { AuthContext } from "../../context/authContext";

const Registration = () => {
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [position, setPosition] = useState(positions[0]);
  const [gender, setGender] = useState(genderData[0]);
  const [contact, setContact] = useState("");
  const [ic, setIc] = useState("");
  const [email, setEmail] = useState("");
  const [joinedDate, setJoinedDate] = useState(null);
  const toaster = useToaster();
  const [errors, setErrors] = useState({});

  const handleAddUser = async () => {
    const newErrors = {};
    if (!name) newErrors.name = "Full Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!gender) newErrors.gender = "Gender is required";
    if (!position) newErrors.position = "Position is required";
    if (!ic) newErrors.ic = "IC Number is required";
    if (!contact) newErrors.contact = "Contact is required";
    if (!joinedDate) newErrors.joinedDate = "Joined date is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const password = generateRandomPassword();

    try {
      const res = await createUserWithEmailAndPassword(auth, email, password );
      await setDoc(doc(db, "users", res.user.uid), {
        name: name,
        email: email,
        contact: contact,
        ic: ic,
        gender: gender,
        position: position,
        password: password,
        timeStamp: serverTimestamp(),
        salary: 0,
        medLeaves: 0,
        annualLeaves: 0,
        subsalesTier: 0,
        projectTier: 0,
        joinedDate: joinedDate ? joinedDate.toISOString() : null,
        updatedBy: currentUser.name
      });
      toaster.push(<Message>User has been registered successfully!</Message>);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setErrors({});
    } catch (e) {
      console.log(e);
    }
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleContactChange = (value) => {
    setContact(value);
  };


  const handleIcChange = (value) => {
    setIc(value);
  };

  return (
    <div className="registration">
      <Container>
        <SideBar1/>
        <Container>
          <NavBar title={"Register New User"} user={currentUser}/>
          <Content>
            <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} onSubmit={handleAddUser}>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="name">
                    <Form.ControlLabel>Full Name</Form.ControlLabel>
                    <Form.Control name="name" onChange={handleNameChange}/>
                    {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.ControlLabel>Email</Form.ControlLabel>
                    <Form.Control name="email" type="email" onChange={handleEmailChange}/>
                    {errors.email && <Form.HelpText style={{ color: 'red' }}>{errors.email}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="gender">
                    <Form.ControlLabel>Gender</Form.ControlLabel>
                    <Dropdown title={gender || "Gender"} onSelect={(value) => setGender(value)}>
                      {genderData.map((data) => (
                        <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </Dropdown>
                    {errors.gender && <Form.HelpText style={{ color: 'red' }}>{errors.gender}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="position">
                    <Form.ControlLabel>Position</Form.ControlLabel>
                    <Dropdown title={position || "Position"} onSelect={(value) => setPosition(value)}>
                      {positions.map((data) => (
                        <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                      ))}
                    </Dropdown>
                    {errors.position && <Form.HelpText style={{ color: 'red' }}>{errors.position}</Form.HelpText>}
                  </Form.Group>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={12}>
                  <Form.Group controlId="icNumber">
                    <Form.ControlLabel>IC Number</Form.ControlLabel>
                    <Form.Control name="icnumber" type="text" onChange={handleIcChange}/>
                    {errors.ic && <Form.HelpText style={{ color: 'red' }}>{errors.ic}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="contactNumber">
                    <Form.ControlLabel>Contact Number</Form.ControlLabel>
                    <Form.Control name="contactNumber" type="text" onChange={handleContactChange}/>
                    {errors.contact && <Form.HelpText style={{ color: 'red' }}>{errors.contact}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group controlId="joinedDate">
                    <Form.ControlLabel>Joined Date</Form.ControlLabel>
                    <DatePicker onChange={(date) => setJoinedDate(date)}/>
                    {errors.joinedDate && <Form.HelpText style={{ color: 'red' }}>{errors.joinedDate}</Form.HelpText>}
                  </Form.Group>
                  <Form.Group>
                    <ButtonToolbar>
                      <Button appearance="primary" type='submit'>Submit</Button>
                      <Button appearance="default">Cancel</Button>
                    </ButtonToolbar>
                  </Form.Group>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Form>
          </Content>
        </Container>
      </Container>
    </div>
  );
}

export default Registration