import './App.css';
import 'rsuite/dist/rsuite.min.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/authContext";
import Login from "./pages/login/Login";
import Home from './pages/home/Home';
import Registration from './pages/registration/Registration';
import Projects from './pages/projects/Projects';
import Account from './pages/account/Account';
import Profile from './pages/profile/Profile';

function App() {
  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/login"/>
  }
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/">   
            <Route path="login" element={<Login />} />
            <Route index element={<RequireAuth><Home /></RequireAuth>} />
            <Route path="users-registration" element={<RequireAuth><Registration/></RequireAuth>}>
              {/* <Route index element={<RequireAuth><List /></RequireAuth>} />
              <Route path=":userId" element={<RequireAuth><Single /></RequireAuth>} />
              <Route
                path="new"
                element={<RequireAuth><New inputs={userInputs} title="Add New User" /></RequireAuth>}
              /> */}
            </Route>
            <Route path="project-list" element={<RequireAuth><Projects/></RequireAuth>}></Route>
            <Route path="account-setting" element={<RequireAuth><Account/></RequireAuth>}></Route>
            <Route path="profile" element={<RequireAuth><Profile/></RequireAuth>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
