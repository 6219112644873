export const positions = [
    "Manager",
    "Sales Agent",
    "Pre-Leader",
    "Leader",
    "Admin"
]

export const genderData = [
   "Male",
   "Female"
]