import { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import {
  Container,
  Header,
  Content,
  Footer,
  Form,
  ButtonToolbar,
  Button,
  Navbar,
  Panel,
  FlexboxGrid,
  Input
} from 'rsuite';
import { doc, getDoc } from "firebase/firestore";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate()

  const {dispatch} = useContext(AuthContext)

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        // Fetch user data from Firestore
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        dispatch({type:"LOGIN", payload: {uid: user.uid, email: user.email, ...userData}})
        navigate("/")
      })
      .catch((error) => {
        setError(true);
      });
    }catch(error){
      
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };
  
  const handlePasswordChange = (value) => {
    setPassword(value);
  };

  return (
    <div className="show-fake-browser login-page">
    <Container>
      <Header>
        <Navbar appearance="inverse">
          <Navbar.Brand>
            <a style={{ color: '#fff' }}>Brand</a>
          </Navbar.Brand>
        </Navbar>
      </Header>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Login</h3>} bordered>
              <Form fluid onSubmit={handleLogin}>
                <Form.Group>
                  <Form.ControlLabel style={{textAlign:"left"}}>Email address</Form.ControlLabel>
                  <Form.Control name="email" onChange={handleEmailChange}/>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel style={{textAlign:"left"}}>Password</Form.ControlLabel>
                  <Form.Control name="password" type="password" autoComplete="off" onChange={handlePasswordChange}/>
                </Form.Group>
                
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={handleLogin}>Sign in</Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  </div>
  );
};

export default Login;