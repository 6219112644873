import { Container, Header, Content } from 'rsuite';
import SideBar1 from '../../components/SideBar1/SideBar1';

const Home = () => {

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        <SideBar1/>
        <Container>
          <Header>
            <h2>Page Title</h2>
          </Header>
          <Content>Content</Content>
        </Container>
      </Container>
    </div>
  );
};

export default Home;