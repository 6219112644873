import React, { useState, useContext, useEffect } from 'react'
import { 
    Container,  
    Content, 
    Form, 
    Button, 
    Dropdown, 
    useToaster, 
    Message,
    Table, 
    Modal,
    Pagination,
    Input,
    InputGroup,
    IconButton
  } from 'rsuite';
import SideBar1 from '../../components/SideBar1/SideBar1';
import SearchIcon from '@rsuite/icons/Search';
import { AuthContext } from '../../context/authContext';
import NavBar from '../../components/NavBar/NavBar';
import { excludeSSTData } from './projectData';
import { doc, serverTimestamp, setDoc, collection, getDocs, updateDoc, deleteDoc } from "firebase/firestore"; 
import { db, } from "../../firebase";

const { Column, HeaderCell, Cell } = Table;


const Projects = () => {
  const {currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setEditId('');
    setName('');
    setCommission('');
    setExcludeSST(excludeSSTData[0]);
  };
  const [name, setName] = useState("");
  const [excludeSST, setExcludeSST] = useState(excludeSSTData[0]);
  const [errors, setErrors] = useState({});
  const [commission, setCommission] = useState("");
  const toaster = useToaster();
  const [projects, setProjects] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState('');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [searchQuery, setSearchQuery] = useState("");

  const handleNameChange = (value) => {
    setName(value);
  };

  const handleCommissionChange = (value) => {
    setCommission(value);
  };

  useEffect(() => {
    const fetchProjects = async () => {
    const querySnapshot = await getDocs(collection(db, "projects"));
    const projectList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

    projectList.sort((a, b) => a.name.localeCompare(b.name));
    setProjects(projectList);
    };

    fetchProjects();
}, []);


  const handleAddProject = async () => {
    const newErrors = {};
    if (!name) newErrors.name = "Full Name is required";
    if (!commission) newErrors.commission = "Commission is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
        if (editMode) {
            await updateDoc(doc(db, "projects", editId), {
              name: name,
              commission: commission,
              excludeSST: excludeSST,
              updatedBy: currentUser.name
            });
            toaster.push(<Message>Project has been updated successfully!</Message>);
          } else {
            await setDoc(doc(db, "projects", `${name}-${serverTimestamp()}`), {
              name: name,
              commission: commission,
              excludeSST: excludeSST,
              timeStamp: serverTimestamp(),
              updatedBy: currentUser.name
            });
            toaster.push(<Message>New project has been added successfully!</Message>);
          }
      
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      setErrors({});
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = (rowData) => {
    setEditMode(true);
    setEditId(rowData.id);
    setName(rowData.name);
    setCommission(rowData.commission);
    setExcludeSST(rowData.excludeSST);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "projects", deleteId));
      toaster.push(<Message>Project deleted successfully!</Message>);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteId('');
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const filteredProjects = projects.filter(project => 
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="registration">
      <Container>
        <SideBar1/>
        <Container>
          <NavBar title={"Project List"} user={currentUser}/>
          <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
          {currentUser.position === "Admin" ? 
            <div style={{textAlign: "left", margin: "20px"}}>
                <Button appearance="primary" onClick={handleOpen}>
                    Add New Project
                </Button>
                <Modal open={open} onClose={handleClose}>
                    <Modal.Header>
                        <Modal.Title>{editMode ? 'Edit Project' : 'Add New Project'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form style={{ textAlign: 'left', marginLeft: "40px", marginTop: "40px"}} >
                        <Form.Group controlId="name">
                            <Form.ControlLabel>Project Name</Form.ControlLabel>
                            <Form.Control name="name" value={name} onChange={handleNameChange}/>
                            {errors.name && <Form.HelpText style={{ color: 'red' }}>{errors.name}</Form.HelpText>}
                        </Form.Group>
                        <Form.Group controlId="commission">
                            <Form.ControlLabel>Commission</Form.ControlLabel>
                            <Form.Control name="commission" type="text" value={commission} onChange={handleCommissionChange}/>
                            {errors.commission && <Form.HelpText style={{ color: 'red' }}>{errors.commission}</Form.HelpText>}
                        </Form.Group>
                        <Form.Group controlId="sst">
                            <Form.ControlLabel>Exclude SST</Form.ControlLabel>
                            <Dropdown title={excludeSST || "Exclude SST"} onSelect={(value) => setExcludeSST(value)}>
                            {excludeSSTData.map((data) => (
                                <Dropdown.Item key={data} eventKey={data}>{data}</Dropdown.Item>
                            ))}
                            </Dropdown>
                            {errors.sst && <Form.HelpText style={{ color: 'red' }}>{errors.sst}</Form.HelpText>}
                        </Form.Group>    
                    </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleAddProject} appearance="primary">
                            {editMode ? 'Update' : 'Add'}
                        </Button>
                        <Button onClick={handleClose} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal open={deleteOpen} onClose={handleDeleteClose}>
                    <Modal.Header>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this project?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleDelete} appearance="primary">
                            Delete
                        </Button>
                        <Button onClick={handleDeleteClose} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div> 
            : <div></div>}
            <div style={{height: "80px", justifyContent: "center", marginTop: "10px", marginRight: "10px"}}>
            <InputGroup style={{ width: 300}}>
              <Input placeholder="Search by project name" value={searchQuery} onChange={handleSearchChange} />
              <InputGroup.Addon>
                <IconButton icon={<SearchIcon />} />
              </InputGroup.Addon>
            </InputGroup>
            </div>
          </div>
          <Content>
            <Table
                height={500}
                data={filteredProjects.slice((activePage - 1) * 10, activePage * 10)}
                onRowClick={rowData => {
                    console.log(rowData);
                }}
                style={{marginLeft: "40px", marginRight: "40px"}}
            >
             <Column width={500}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell  align="left" dataKey="name" />
                </Column>

                <Column width={200}>
                    <HeaderCell>Commission Percentage (%)</HeaderCell>
                    <Cell dataKey="commission" />
                </Column>

                <Column width={100}>
                    <HeaderCell>Exclude SST</HeaderCell>
                    <Cell dataKey="excludeSST" />
                </Column>
                {
                    currentUser.position === "Admin"
                    ? <Column width={200} fixed="right">
                        <HeaderCell>Action</HeaderCell>

                        <Cell style={{ padding: '6px' }}>
                        {rowData => (
                            <div>
                                <Button appearance="link" onClick={() => handleEdit(rowData)}>
                              Edit
                            </Button>
                            <Button appearance="link" onClick={() => handleDeleteOpen(rowData.id)} style={{ marginLeft: '10px' }}>
                              Delete
                            </Button>
                            </div>
                        )}
                        </Cell>
                    </Column>
                    :<div></div>
                }
            </Table>
            <Pagination total={projects.length} limit={10} activePage={activePage} onChangePage={setActivePage} style={{marginLeft: "40px", marginTop: "20px"}}/>
          </Content>
        </Container>
      </Container>
    </div>
  )
}

export default Projects